import { Button } from '@mui/material';
import React from 'react';

const ResetButton = ({ handleReset }) => {
	return (
		<>
			<Button
				onClick={handleReset}
				style={{ margin: '0 15px 15px 15px' }}
				variant='contained'
				color='primary'
			>
				Reset
			</Button>
		</>
	);
};

export default ResetButton;
