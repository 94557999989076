const colors = [
	{ name: 'red', group: 'Colors' },
	{ name: 'orange', group: 'Colors' },
	{ name: 'yellow', group: 'Colors' },
	{ name: 'green', group: 'Colors' },
	{ name: 'blue', group: 'Colors' },
	{ name: 'purple', group: 'Colors' },
	{ name: 'pink', group: 'Colors' },
	{ name: 'brown', group: 'Colors' },
	{ name: 'black', group: 'Colors' },
	{ name: 'white', group: 'Colors' },
	{ name: 'gray', group: 'Colors' },
	{ name: 'sepia', group: 'Colors' },
	{ name: 'monochrome', group: 'Descriptors' },
	{ name: 'natural colors', group: 'Descriptors' },
	{ name: 'clear colors', group: 'Descriptors' },
	{ name: 'compatible colors', group: 'Descriptors' },
	{ name: 'distinctive colors', group: 'Descriptors' },
	{ name: 'lively colors', group: 'Descriptors' },
	{ name: 'stimulating colors', group: 'Descriptors' },
	{ name: 'subtle colors', group: 'Descriptors' },
	{ name: 'sympathetic colors', group: 'Descriptors' },
	{ name: 'artificial colors', group: 'Descriptors' },
	{ name: 'clashing colors', group: 'Descriptors' },
	{ name: 'depressing colors', group: 'Descriptors' },
	{ name: 'discordant colors', group: 'Descriptors' },
	{ name: 'garish colors', group: 'Descriptors' },
	{ name: 'gaudy colors', group: 'Descriptors' },
	{ name: 'jarring colors', group: 'Descriptors' },
	{ name: 'unfriendly colors', group: 'Descriptors' },
	{ name: 'bright colors', group: 'Descriptors' },
	{ name: 'brilliant colors', group: 'Descriptors' },
	{ name: 'deep colors', group: 'Descriptors' },
	{ name: 'earthy colors', group: 'Descriptors' },
	{ name: 'harmonious colors', group: 'Descriptors' },
	{ name: 'intense colors', group: 'Descriptors' },
	{ name: 'rich colors', group: 'Descriptors' },
	{ name: 'saturated colors', group: 'Descriptors' },
	{ name: 'strong colors', group: 'Descriptors' },
	{ name: 'vibrant colors', group: 'Descriptors' },
	{ name: 'vivid colors', group: 'Descriptors' },
	{ name: 'dull colors', group: 'Descriptors' },
	{ name: 'flat colors', group: 'Descriptors' },
	{ name: 'insipid colors', group: 'Descriptors' },
	{ name: 'pale colors', group: 'Descriptors' },
	{ name: 'mellow colors', group: 'Descriptors' },
	{ name: 'muted colors', group: 'Descriptors' },
	{ name: 'subdued colors', group: 'Descriptors' },
	{ name: 'quiet colors', group: 'Descriptors' },
	{ name: 'weak colors', group: 'Descriptors' },
	{ name: 'cool colors', group: 'Descriptors' },
	{ name: 'cold colors', group: 'Descriptors' },
	{ name: 'warm colors', group: 'Descriptors' },
	{ name: 'hot colors', group: 'Descriptors' },
	{ name: 'light colors', group: 'Descriptors' },
	{ name: 'dark colors', group: 'Descriptors' },
	{ name: 'blended colors', group: 'Descriptors' },
	{ name: 'broken colors', group: 'Descriptors' },
	{ name: 'mixed colors', group: 'Descriptors' },
	{ name: 'muddled colors', group: 'Descriptors' },
	{ name: 'muddied colors', group: 'Descriptors' },
	{ name: 'pure colors', group: 'Descriptors' },
	{ name: 'complementary colors', group: 'Descriptors' },
	{ name: 'contrasting colors', group: 'Descriptors' },
	{ name: 'harmonious colors', group: 'Descriptors' },
];

export default colors;
