import './App.css';
import { Prompt } from './components/prompt/Prompt';
import { createTheme, Grid, useMediaQuery } from '@mui/material';
import ButtonAppBar from './components/button-app-bar/ButtonAppBar';
import FooterAppBar from './components/footer-app-bar/FooterAppBar';
import { useEffect, useState } from 'react';
import RandomButton from './components/random-button/RandomButton';
import PromptHistory from './components/prompt-history/PromptHistory';

import logo from './images/logo.png';

import stableDiffusion from './images/stable-diffusion.png';
import dalle from './images/dalle-2.png';
import midjourney from './images/midjourney.png';
import ResetButton from './components/reset-button/ResetButton';
import Virtualize from './components/virtualize/Virtualize';

function App() {
	const [promptText, setPromptText] = useState('Prompt goes here...');
	const [previousPrompts, setPreviousPrompts] = useState([]);
	const [useRandom, setUseRandom] = useState(false);
	const [useReset, setUseReset] = useState(false);

	const [styles, setStyles] = useState([]);
	const [colors, setColors] = useState([]);
	const [mediums, setMediums] = useState([]);

	const [moods, setMoods] = useState([]);
	const [forms, setForms] = useState([]);
	const [presentations, setPresentations] = useState([]);

	const handleGenerateRandom = () => {
		setUseRandom(true);
		setTimeout(() => {
			setUseRandom(false);
		}, 100);
	};

	const handleReset = () => {
		setUseReset(true);
		setTimeout(() => {
			setUseReset(false);
		}, 100);
	};

	const handleSelectStyle = (items) => setStyles(items);
	const handleSelectColor = (items) => setColors(items);
	const handleSelectMedium = (items) => setMediums(items);

	const handleSelectMood = (items) => setMoods(items);
	const handleSelectForm = (items) => setForms(items);
	const handleSelectPresentation = (items) => setPresentations(items);

	const handlePromptCopy = () => {
		if (promptText.length > 0) {
			setPreviousPrompts([promptText, ...previousPrompts]);
		}
	};

	// Todo - concat all
	useEffect(() => {
		let all = [
			...styles,
			...colors,
			...mediums,
			...moods,
			...forms,
			...presentations,
		];
		all = all.map((item) => {
			return item.name;
		});
		setPromptText(all.toString().split(',').join(', '));
	}, [styles, colors, mediums, moods, forms, presentations]);

	const theme = createTheme({
		breakpoints: {
			values: {
				xs: 0,
				sm: 600,
				md: 900,
				lg: 1200,
				xl: 1536,
			},
		},
	});

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
		defaultMatches: true,
	});

	return (
		<>
			{/* {showInstructions && <Instructions />} */}
			<Grid
				container
				style={{ justifyContent: 'center' }}
				columnSpacing={{ xs: 1, sm: 9, md: 4 }}
			>
				<Grid item xs={12}>
					<ButtonAppBar />
				</Grid>
				<Grid
					container
					style={{
						justifyContent: 'center',
						padding: '10px 15px',
					}}
					spacing={isMobile ? 1 : 3}
				>
					<Grid
						item
						xs={12}
						style={{
							textAlign: 'center',
						}}
					>
						<img alt='prompt spells' src={logo} className='logo' />
					</Grid>
					<Grid item xs={0} sm={2}></Grid>
					<Grid item xs={12} sm={8}>
						<Prompt
							promptText={promptText}
							handlePromptCopy={handlePromptCopy}
						/>
					</Grid>
					<Grid item xs={0} sm={2}></Grid>
					<Grid item xs={12} align='center'>
						<RandomButton
							handleGenerateRandom={handleGenerateRandom}
						/>
						{promptText.length > 0 && (
							<ResetButton handleReset={handleReset} />
						)}
					</Grid>
				</Grid>
				<Grid
					container
					style={{
						justifyContent: 'center',
						padding: '10px 125px',
					}}
					spacing={3}
				>
					<Grid item>
						<Virtualize
							useRandom={useRandom}
							useReset={useReset}
							category={'Style'}
							handleSelectAction={handleSelectStyle}
						/>
					</Grid>
					<Grid item>
						<Virtualize
							useRandom={useRandom}
							useReset={useReset}
							category={'Color'}
							handleSelectAction={handleSelectColor}
						/>
					</Grid>
					<Grid item>
						<Virtualize
							useRandom={useRandom}
							useReset={useReset}
							category={'Medium'}
							handleSelectAction={handleSelectMedium}
						/>
					</Grid>
					<Grid item>
						<Virtualize
							useRandom={useRandom}
							useReset={useReset}
							category={'Mood'}
							handleSelectAction={handleSelectMood}
						/>
					</Grid>
					<Grid item>
						<Virtualize
							useRandom={useRandom}
							useReset={useReset}
							category={'Form'}
							handleSelectAction={handleSelectForm}
						/>
					</Grid>
					<Grid item>
						<Virtualize
							useRandom={useRandom}
							useReset={useReset}
							category={'Presentation'}
							handleSelectAction={handleSelectPresentation}
						/>
					</Grid>
					{/* <Grid item>
						<Virtualize />
					</Grid>
					<Grid item>
						<Virtualize />
					</Grid>
					<Grid item>
						<Virtualize />
					</Grid> */}
				</Grid>
				<Grid item xs={0} sm={1}></Grid>
				<Grid item xs={10} sm={8}>
					<PromptHistory prompts={previousPrompts} />
				</Grid>
				<Grid item xs={0} sm={1}></Grid>
				<Grid
					container
					style={{
						justifyContent: 'center',
						marginBottom: '180px',
					}}
					columnSpacing={{ xs: 1, sm: 3, md: 10 }}
				>
					<Grid item>
						<a
							href='https://beta.dreamstudio.ai/dream'
							target='_blank'
							rel='noreferrer'
							className='ai-website-url'
						>
							<img
								alt='Stable Diffusion'
								src={stableDiffusion}
								className='ai-website-image'
							/>
							<div className='website-label'>
								Stable Diffusion
							</div>
						</a>
					</Grid>
					<Grid item>
						<a
							href='https://openai.com/dall-e-2/'
							target='_blank'
							rel='noreferrer'
							className='ai-website-url'
						>
							<img
								alt='DALLE'
								src={dalle}
								className='ai-website-image'
							/>
							<div className='website-label'>DALL·E 2</div>
						</a>
					</Grid>
					<Grid item>
						<a
							href='https://midjourney.com/'
							target='_blank'
							rel='noreferrer'
							className='ai-website-url'
						>
							<img
								alt='Midjourney'
								src={midjourney}
								className='ai-website-image'
							/>
							<div className='website-label'>Midjourney</div>
						</a>
					</Grid>
				</Grid>
				<Grid item xs={12}></Grid>
			</Grid>
			<FooterAppBar />
		</>
	);
}

export default App;
