import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

export default function FooterAppBar() {
	return (
		<div
			position='absolute'
			style={{
				background: '#37474f',
				bottom: 0,
			}}
		>
			<Toolbar>
				<Typography
					color='white'
					variant='h6'
					component='div'
					sx={{ flexGrow: 1, position: 'absolute', right: 25 }}
				>
					created by{' '}
					<a
						href='https://timfinnigan.com'
						target='_blank'
						rel='noreferrer'
						style={{
							color: 'white',
						}}
					>
						tim finnigan
					</a>
				</Typography>
			</Toolbar>
		</div>
	);
}
