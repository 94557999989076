import React, { useState } from 'react';
import { Alert, InputAdornment, Slide, TextField } from '@mui/material';
import { ContentCopy, Check } from '@mui/icons-material';

export const Prompt = ({ promptText, handlePromptCopy }) => {
	const [showCopySuccessAlert, setShowCopySuccessAlert] = useState(false);

	return (
		<>
			<Slide
				direction='up'
				in={showCopySuccessAlert && promptText.length > 0}
				mountOnEnter
				unmountOnExit
			>
				<Alert
					icon={<Check />}
					style={{
						fontSize: 18,
						position: 'fixed',
						bottom: 50,
						right: -10,
						zIndex: 999,
						marginTop: -10,
						border: '1px solid lightgrey',
						width: '260px',
						boxShadow: '0 1px 1px 0',
					}}
				>
					Copied to clipboard!
				</Alert>
			</Slide>
			<TextField
				style={{ width: '100%' }}
				id='outlined-basic'
				value={promptText}
				variant='outlined'
				InputProps={{
					endAdornment: (
						<InputAdornment
							style={{ cursor: 'pointer' }}
							title='Copy prompt'
							position='end'
						>
							<ContentCopy
								onClick={() => {
									if (promptText.length === 0) return;
									handlePromptCopy(promptText.toString());
									setShowCopySuccessAlert(true);
									navigator.clipboard.writeText(
										promptText.toString()
									);
									setTimeout(() => {
										setShowCopySuccessAlert(false);
									}, 3000);
								}}
							/>
						</InputAdornment>
					),
				}}
			/>
		</>
	);
};
