const Aesthetics = [
	{ name: 'Hanna-Barbera', group: 'Pop Culture' },
	{ name: 'Pixar', group: 'Pop Culture' },
	{ name: 'Studio Ghibli', group: 'Pop Culture' },
	{ name: 'Marvel', group: 'Pop Culture' },
	{ name: 'political cartoon', group: 'Pop Culture' },
	{ name: 'Disney', group: 'Pop Culture' },
	{ name: 'collage', group: 'Digital' },
	{ name: 'cybertronic', group: 'Digital' },
	{ name: 'digital painting', group: 'Digital' },
	{ name: 'layered paper', group: 'Digital' },
	{ name: 'low poly', group: 'Digital' },
	{ name: 'screen print', group: 'Digital' },
	{ name: 'sticker illustration', group: 'Digital' },
	{ name: 'storybook', group: 'Digital' },
	{ name: 'unreal engine', group: 'Digital' },
	{ name: 'vector art', group: 'Digital' },
	{ name: 'blueprint', group: 'Instructional' },
	{ name: 'botanical illustration', group: 'Instructional' },
	{ name: 'cutaway', group: 'Instructional' },
	{ name: 'IKEA manual', group: 'Instructional' },
	{ name: 'instruction manual', group: 'Instructional' },
	{ name: 'mythological map', group: 'Instructional' },
	{ name: 'patent drawing', group: 'Instructional' },
	{ name: 'scientific diagram', group: 'Instructional' },
	{ name: 'Voronoi diagram', group: 'Instructional' },
	{ name: 'Voynich manuscript', group: 'Instructional' },
	{ name: 'WikiHow guide', group: 'Instructional' },
	{ name: 'Ancient art', group: 'Early art history' },
	{ name: 'Byzantine', group: 'Early art history' },
	{ name: 'Minoan', group: 'Early art history' },
	{ name: 'Fayum portrait', group: 'Early art history' },
	{ name: 'Roman mosaic', group: 'Early art history' },
	{ name: 'Art Nouveau', group: 'Pre-modern' },
	{ name: 'Ukiyo-e', group: 'Pre-modern' },
	{ name: 'Baroque', group: 'Pre-modern' },
	{ name: 'Classicism', group: 'Pre-modern' },
	{ name: 'Impressionism', group: 'Pre-modern' },
	{ name: 'Pointillism', group: 'Pre-modern' },
	{ name: 'Mannerism', group: 'Pre-modern' },
	{ name: 'Naturalism', group: 'Pre-modern' },
	{ name: 'Neoclassicism', group: 'Pre-modern' },
	{ name: 'Realism', group: 'Pre-modern' },
	{ name: 'Renaissance', group: 'Pre-modern' },
	{ name: 'Rococo', group: 'Pre-modern' },
	{ name: 'Romanticism', group: 'Pre-modern' },
	{ name: 'Symbolist painting', group: 'Pre-modern' },
	{ name: 'Abstract expressionism', group: 'Modern' },
	{ name: 'Afrofuturism', group: 'Modern' },
	{ name: 'Art Deco', group: 'Modern' },
	{ name: 'Bauhaus', group: 'Modern' },
	{ name: 'CoBrA', group: 'Modern' },
	{ name: 'Color field painting', group: 'Modern' },
	{ name: 'Conceptualism', group: 'Modern' },
	{ name: 'Constructivism', group: 'Modern' },
	{ name: 'Corporate Memphis', group: 'Modern' },
	{ name: 'Cubism', group: 'Modern' },
	{ name: 'Dadaism', group: 'Modern' },
	{ name: 'Digital Art', group: 'Modern' },
	{ name: 'De Stijl', group: 'Modern' },
	{ name: 'Expressionism', group: 'Modern' },
	{ name: 'Fauvism', group: 'Modern' },
	{ name: 'Futurism', group: 'Modern' },
	{ name: 'Harlem Renaissance', group: 'Modern' },
	{ name: 'Land Art', group: 'Modern' },
	{ name: 'Modernism', group: 'Modern' },
	{ name: 'Metaphysical painting', group: 'Modern' },
	{ name: 'Mexican muralism', group: 'Modern' },
	{ name: 'Neon art', group: 'Modern' },
	{ name: 'New Objectivity', group: 'Modern' },
	{ name: 'Op Art', group: 'Modern' },
	{ name: 'Orphism', group: 'Modern' },
	{ name: 'Precisionism', group: 'Modern' },
	{ name: 'Purism', group: 'Modern' },
	{ name: 'Retro-Futurism', group: 'Modern' },
	{ name: 'Street art', group: 'Modern' },
	{ name: 'Suprematism', group: 'Modern' },
	{ name: 'Surrealism', group: 'Modern' },
	{ name: 'Vorticism', group: 'Modern' },
	{ name: 'Zero Group', group: 'Modern' },
	{ name: 'Arte Povera', group: 'Contemporary' },
	{ name: 'Body Art', group: 'Contemporary' },
	{ name: 'Cynical Realism', group: 'Contemporary' },
	{ name: 'Computer Art', group: 'Contemporary' },
	{ name: 'Conceptualism', group: 'Contemporary' },
	{ name: 'Contemporary Photography', group: 'Contemporary' },
	{ name: 'Contemporary Realism', group: 'Contemporary' },
	{ name: 'Corporate Memphis', group: 'Contemporary' },
	{ name: 'Deconstructivism', group: 'Contemporary' },
	{ name: 'Earthworks', group: 'Contemporary' },
	{ name: 'Fluxus Movement', group: 'Contemporary' },
	{ name: 'Graffiti', group: 'Contemporary' },
	{ name: 'Installation', group: 'Contemporary' },
	{ name: 'Maximalism', group: 'Modern' },
	{ name: 'Minimalism', group: 'Contemporary' },
	{ name: 'Neo-Expressionism', group: 'Contemporary' },
	{ name: 'Neo-Impressionalism', group: 'Modern' },
	{ name: 'Neo-Pop', group: 'Contemporary' },
	{ name: 'Pixel Art', group: 'Contemporary' },
	{ name: 'New Leipzig School', group: 'Contemporary' },
	{ name: 'New Subjectivity', group: 'Contemporary' },
	{ name: 'Performance Art', group: 'Contemporary' },
	{ name: 'Photo-Realism', group: 'Contemporary' },
	{ name: 'Pop Art', group: 'Contemporary' },
	{ name: 'Post-Minimalism', group: 'Contemporary' },
	{ name: 'Projection Art', group: 'Contemporary' },
	{ name: 'Stuckism', group: 'Contemporary' },
	{ name: 'Supports/Surfaces', group: 'Contemporary' },
	{ name: 'Transavanguardia', group: 'Contemporary' },
	{ name: 'Video Installations', group: 'Contemporary' },
	{ name: 'Word Art', group: 'Contemporary' },
	{ name: 'Scandinavian', group: 'Aesthetic' },
	{ name: "'70s Disco", group: 'Aesthetic' },
	{ name: '2-Tone', group: 'Aesthetic' },
	{ name: "1950's Suburbia", group: 'Aesthetic' },
	{ name: '2020 Alt', group: 'Aesthetic' },
	{ name: '2014 Girly Tumblr', group: 'Aesthetic' },
	{ name: '2K Animecore', group: 'Aesthetic' },
	{ name: 'Abstract Tech', group: 'Aesthetic' },
	{ name: 'Acid Pixie', group: 'Aesthetic' },
	{ name: 'Acidwave', group: 'Aesthetic' },
	{ name: 'Acubi', group: 'Aesthetic' },
	{ name: 'Adventure Pulp', group: 'Aesthetic' },
	{ name: 'Adventurecore', group: 'Aesthetic' },
	{ name: 'Aetherpunk', group: 'Aesthetic' },
	{ name: 'Afro-Victorian', group: 'Aesthetic' },
	{ name: 'After Hours', group: 'Aesthetic' },
	{ name: 'Agropeople', group: 'Aesthetic' },
	{ name: 'Alien', group: 'Aesthetic' },
	{ name: 'Alternative', group: 'Aesthetic' },
	{ name: 'Americana', group: 'Aesthetic' },
	{ name: 'Analog Horror', group: 'Aesthetic' },
	{ name: 'American Pioneers', group: 'Aesthetic' },
	{ name: 'American Revolution', group: 'Aesthetic' },
	{ name: 'American Thanksgiving', group: 'Aesthetic' },
	{ name: 'American Tourist Traps', group: 'Aesthetic' },
	{ name: 'Ancient Egypt', group: 'Aesthetic' },
	{ name: 'Androgynous', group: 'Aesthetic' },
	{ name: 'Anemoiacore', group: 'Aesthetic' },
	{ name: 'Angelcore', group: 'Aesthetic' },
	{ name: 'Anglocore', group: 'Aesthetic' },
	{ name: 'Anglo Gothic', group: 'Aesthetic' },
	{ name: 'Angura', group: 'Aesthetic' },
	{ name: 'Animecore', group: 'Aesthetic' },
	{ name: 'Antique Grunge', group: 'Aesthetic' },
	{ name: 'Anti-Fashion', group: 'Aesthetic' },
	{ name: 'Arcade', group: 'Aesthetic' },
	{ name: 'Arcadecore', group: 'Aesthetic' },
	{ name: 'Art Academia', group: 'Aesthetic' },
	{ name: 'Arts and Crafts Movement', group: 'Aesthetic' },
	{ name: 'Art Deco', group: 'Aesthetic' },
	{ name: 'Art Nouveau', group: 'Aesthetic' },
	{ name: 'Athlete', group: 'Aesthetic' },
	{ name: 'Atompunk', group: 'Aesthetic' },
	{ name: 'Auroracore', group: 'Aesthetic' },
	{ name: 'Autumn Academia', group: 'Aesthetic' },
	{ name: 'Autumn', group: 'Aesthetic' },
	{ name: 'Avant Apocalypse', group: 'Aesthetic' },
	{ name: 'Avant-garde', group: 'Aesthetic' },
	{ name: 'Babycore', group: 'Aesthetic' },
	{ name: 'Babygirl', group: 'Aesthetic' },
	{ name: 'Back-to-School', group: 'Aesthetic' },
	{ name: 'Baddie', group: 'Aesthetic' },
	{ name: 'Ballet Academia', group: 'Aesthetic' },
	{ name: 'Barbiecore', group: 'Aesthetic' },
	{ name: 'Bardcore', group: 'Aesthetic' },
	{ name: 'Baroque', group: 'Aesthetic' },
	{ name: 'Bauhaus', group: 'Aesthetic' },
	{ name: 'Beach Bunny', group: 'Aesthetic' },
	{ name: 'Beach Day', group: 'Aesthetic' },
	{ name: 'Beatnik', group: 'Aesthetic' },
	{ name: 'Biker', group: 'Aesthetic' },
	{ name: 'Bimbocore', group: 'Aesthetic' },
	{ name: 'Biopunk', group: 'Aesthetic' },
	{ name: 'Bizarro Fiction', group: 'Aesthetic' },
	{ name: 'Black-Holed Meme', group: 'Aesthetic' },
	{ name: 'Bloomcore', group: 'Aesthetic' },
	{ name: 'Bodikon', group: 'Aesthetic' },
	{ name: 'Bohemian', group: 'Aesthetic' },
	{ name: 'Bombacore', group: 'Aesthetic' },
	{ name: 'Bookstore Girl', group: 'Aesthetic' },
	{ name: 'Bosozoku', group: 'Aesthetic' },
	{ name: 'Boujee', group: 'Aesthetic' },
	{ name: 'Britpop', group: 'Aesthetic' },
	{ name: 'Bro', group: 'Aesthetic' },
	{ name: 'Bronzepunk', group: 'Aesthetic' },
	{ name: 'Bubble Bujo', group: 'Aesthetic' },
	{ name: 'Bubble Goth', group: 'Aesthetic' },
	{ name: 'Bubblegum Witch', group: 'Aesthetic' },
	{ name: 'Cabincore', group: 'Aesthetic' },
	{ name: 'Cagole', group: 'Aesthetic' },
	{ name: 'Camp', group: 'Aesthetic' },
	{ name: 'Campcore', group: 'Aesthetic' },
	{ name: 'Candycore', group: 'Aesthetic' },
	{ name: 'Cargopunk', group: 'Aesthetic' },
	{ name: 'Carnivalcore', group: 'Aesthetic' },
	{ name: 'Cartooncore', group: 'Aesthetic' },
	{ name: 'Casino', group: 'Aesthetic' },
	{ name: 'Cassette Futurism', group: 'Aesthetic' },
	{ name: 'Celtic', group: 'Aesthetic' },
	{ name: 'Changelingcore', group: 'Aesthetic' },
	{ name: 'Chaotic Academia', group: 'Aesthetic' },
	{ name: 'Chav', group: 'Aesthetic' },
	{ name: 'Cheiron Crush', group: 'Aesthetic' },
	{ name: 'Cherry Emoji Twitter', group: 'Aesthetic' },
	{ name: 'Chic Modernist', group: 'Aesthetic' },
	{ name: 'Cholo', group: 'Aesthetic' },
	{ name: 'Christcore', group: 'Aesthetic' },
	{ name: 'Christian Girl Autumn', group: 'Aesthetic' },
	{ name: 'Christmas', group: 'Aesthetic' },
	{ name: 'Chunyu', group: 'Aesthetic' },
	{ name: 'City Pop', group: 'Aesthetic' },
	{ name: 'Classic Academia', group: 'Aesthetic' },
	{ name: 'Cleancore', group: 'Aesthetic' },
	{ name: 'Clockpunk', group: 'Aesthetic' },
	{ name: 'Cloudcore', group: 'Aesthetic' },
	{ name: 'Clowncore', group: 'Aesthetic' },
	{ name: 'Club', group: 'Aesthetic' },
	{ name: 'Cluttercore', group: 'Aesthetic' },
	{ name: 'Coastal Grandmother', group: 'Aesthetic' },
	{ name: 'Cutecore', group: 'Aesthetic' },
	{ name: 'Coffee House/Cafe', group: 'Aesthetic' },
	{ name: 'Coffinwood', group: 'Aesthetic' },
	{ name: 'Colourful Black', group: 'Aesthetic' },
	{ name: 'Comfy/Cozy', group: 'Aesthetic' },
	{ name: 'Concore', group: 'Aesthetic' },
	{ name: 'Corporate', group: 'Aesthetic' },
	{ name: 'Corporate Punk', group: 'Aesthetic' },
	{ name: 'Corecore', group: 'Aesthetic' },
	{ name: 'Cottagecore', group: 'Aesthetic' },
	{ name: 'Cottagegore', group: 'Aesthetic' },
	{ name: 'Coquette Academia', group: 'Aesthetic' },
	{ name: 'Coquette', group: 'Aesthetic' },
	{ name: 'Country', group: 'Aesthetic' },
	{ name: 'Cozy Childhood Hideaway', group: 'Aesthetic' },
	{ name: 'C-Pop', group: 'Aesthetic' },
	{ name: 'Craftcore', group: 'Aesthetic' },
	{ name: 'Crowcore', group: 'Aesthetic' },
	{ name: 'Crustpunk', group: 'Aesthetic' },
	{ name: 'Cryptid Academia', group: 'Aesthetic' },
	{ name: 'Cryptidcore', group: 'Aesthetic' },
	{ name: 'Cuddle Party', group: 'Aesthetic' },
	{ name: 'Cultcore', group: 'Aesthetic' },
	{ name: 'Cult Party Kei', group: 'Aesthetic' },
	{ name: 'Cyberdelic', group: 'Aesthetic' },
	{ name: 'Cyber Fairy Grunge', group: 'Aesthetic' },
	{ name: 'Cyberghetto', group: 'Aesthetic' },
	{ name: 'Cybergoth', group: 'Aesthetic' },
	{ name: 'Cybergrunge', group: 'Aesthetic' },
	{ name: 'CyberneticPunk', group: 'Aesthetic' },
	{ name: 'Cyberparadism', group: 'Aesthetic' },
	{ name: 'Cyberpop', group: 'Aesthetic' },
	{ name: 'Cyberprep', group: 'Aesthetic' },
	{ name: 'Cyberpunk', group: 'Aesthetic' },
	{ name: 'Danish Pastel', group: 'Aesthetic' },
	{ name: 'DarkErrorcore', group: 'Aesthetic' },
	{ name: 'Dark Academia', group: 'Aesthetic' },
	{ name: 'Dark Fantasy', group: 'Aesthetic' },
	{ name: 'Dark Naturalism', group: 'Aesthetic' },
	{ name: 'Dark Nautical', group: 'Aesthetic' },
	{ name: 'Dark Paradise', group: 'Aesthetic' },
	{ name: 'Darkcore', group: 'Aesthetic' },
	{ name: 'Darkest Academia', group: 'Aesthetic' },
	{ name: 'Daydreampunk', group: 'Aesthetic' },
	{ name: 'Dazecore', group: 'Aesthetic' },
	{ name: 'De Stijl', group: 'Aesthetic' },
	{ name: 'Decopunk', group: 'Aesthetic' },
	{ name: 'Decora', group: 'Aesthetic' },
	{ name: 'Delicate Sweet', group: 'Aesthetic' },
	{ name: 'Desertwave', group: 'Aesthetic' },
	{ name: 'Desi Romantic Academia', group: 'Aesthetic' },
	{ name: 'Dethereal', group: 'Aesthetic' },
	{ name: 'Dieselpunk', group: 'Aesthetic' },
	{ name: 'Diner', group: 'Aesthetic' },
	{ name: 'Dionysism', group: 'Aesthetic' },
	{ name: 'Dollette', group: 'Aesthetic' },
	{ name: 'Dolly Kei', group: 'Aesthetic' },
	{ name: 'Downtown Girl', group: 'Aesthetic' },
	{ name: 'Dracopunk', group: 'Aesthetic' },
	{ name: 'Dragoncore', group: 'Aesthetic' },
	{ name: 'Dreamcore', group: 'Aesthetic' },
	{ name: 'Dreamy', group: 'Aesthetic' },
	{ name: 'Dual Kawaii', group: 'Aesthetic' },
	{ name: 'Duckcore', group: 'Aesthetic' },
	{ name: 'Dullcore', group: 'Aesthetic' },
	{ name: 'Dungeon Synth', group: 'Aesthetic' },
	{ name: 'E-Boy', group: 'Aesthetic' },
	{ name: 'E-Girl', group: 'Aesthetic' },
	{ name: 'Earthcore', group: 'Aesthetic' },
	{ name: 'ElectroPop 08', group: 'Aesthetic' },
	{ name: 'Electro Swing', group: 'Aesthetic' },
	{ name: 'Elizabethan England', group: 'Aesthetic' },
	{ name: 'Emo', group: 'Aesthetic' },
	{ name: 'English Major', group: 'Aesthetic' },
	{ name: 'Equestrian', group: 'Aesthetic' },
	{ name: 'Ethereal', group: 'Aesthetic' },
	{ name: 'Europunk', group: 'Aesthetic' },
	{ name: 'Fairy Academia', group: 'Aesthetic' },
	{ name: 'Fairy Grunge', group: 'Aesthetic' },
	{ name: 'Fairy Kei', group: 'Aesthetic' },
	{ name: 'Fairy Tale', group: 'Aesthetic' },
	{ name: 'Fairycore', group: 'Aesthetic' },
	{ name: 'Fanfare', group: 'Aesthetic' },
	{ name: 'Fantasy', group: 'Aesthetic' },
	{ name: 'Fantasy Astronomy', group: 'Aesthetic' },
	{ name: 'Fashwave', group: 'Aesthetic' },
	{ name: "Farmer's Daughter", group: 'Aesthetic' },
	{ name: 'Femme Fatale', group: 'Aesthetic' },
	{ name: 'Feralcore', group: 'Aesthetic' },
	{ name: 'Film Noir', group: 'Aesthetic' },
	{ name: 'Flapper', group: 'Aesthetic' },
	{ name: 'Flat Design', group: 'Aesthetic' },
	{ name: 'Folk Punk', group: 'Aesthetic' },
	{ name: 'Foodie', group: 'Aesthetic' },
	{ name: 'Forestpunk', group: 'Aesthetic' },
	{ name: 'French Girl', group: 'Aesthetic' },
	{ name: 'Frogcore', group: 'Aesthetic' },
	{ name: 'Frutiger Aero', group: 'Aesthetic' },
	{ name: 'Frutiger Metro', group: 'Aesthetic' },
	{ name: 'Funky Seasons', group: 'Aesthetic' },
	{ name: 'Furry', group: 'Aesthetic' },
	{ name: 'Futago', group: 'Aesthetic' },
	{ name: 'Gadgetpunk', group: 'Aesthetic' },
	{ name: 'Game Night', group: 'Aesthetic' },
	{ name: 'Gamercore', group: 'Aesthetic' },
	{ name: 'Gamine', group: 'Aesthetic' },
	{ name: 'Geek', group: 'Aesthetic' },
	{ name: 'Gen X Soft Club', group: 'Aesthetic' },
	{ name: 'Ghostcore', group: 'Aesthetic' },
	{ name: 'Girl-Next-Door', group: 'Aesthetic' },
	{ name: 'Glam Girl', group: 'Aesthetic' },
	{ name: 'Glam Rock', group: 'Aesthetic' },
	{ name: 'Glitchcore', group: 'Aesthetic' },
	{ name: 'Gloomcore', group: 'Aesthetic' },
	{ name: 'Glowwave', group: 'Aesthetic' },
	{ name: 'Goblin Academia', group: 'Aesthetic' },
	{ name: 'Goblincore', group: 'Aesthetic' },
	{ name: 'Golden Age of Detective Fiction', group: 'Aesthetic' },
	{ name: 'Golden Hour', group: 'Aesthetic' },
	{ name: 'Gonzo', group: 'Aesthetic' },
	{ name: 'Gopnik', group: 'Aesthetic' },
	{ name: 'Gorpcore', group: 'Aesthetic' },
	{ name: 'Goth', group: 'Aesthetic' },
	{ name: 'Gothcore', group: 'Aesthetic' },
	{ name: 'Gothic', group: 'Aesthetic' },
	{ name: 'Grandmillenial', group: 'Aesthetic' },
	{ name: 'Grandparentcore', group: 'Aesthetic' },
	{ name: 'Greaser', group: 'Aesthetic' },
	{ name: 'Green Academia', group: 'Aesthetic' },
	{ name: 'Grifes', group: 'Aesthetic' },
	{ name: 'Grindhouse', group: 'Aesthetic' },
	{ name: 'Groundcore', group: 'Aesthetic' },
	{ name: 'Grunge', group: 'Aesthetic' },
	{ name: 'Gull Girl', group: 'Aesthetic' },
	{ name: 'Gurokawa', group: 'Aesthetic' },
	{ name: 'Gyaru', group: 'Aesthetic' },
	{ name: 'Hackercore', group: 'Aesthetic' },
	{ name: 'Halloween', group: 'Aesthetic' },
	{ name: 'Hallyu', group: 'Aesthetic' },
	{ name: 'Happycore', group: 'Aesthetic' },
	{ name: 'Hatecore', group: 'Aesthetic' },
	{ name: 'Hauntology', group: 'Aesthetic' },
	{ name: 'Haussmann Paris', group: 'Aesthetic' },
	{ name: 'Health Goth', group: 'Aesthetic' },
	{ name: 'Heatwave', group: 'Aesthetic' },
	{ name: 'Heistcore', group: 'Aesthetic' },
	{ name: 'Hellenic', group: 'Aesthetic' },
	{ name: 'Helvetica Aqua Aero', group: 'Aesthetic' },
	{ name: 'Hermaphroditus', group: 'Aesthetic' },
	{ name: 'Hermitpunk', group: 'Aesthetic' },
	{ name: 'Hexatron', group: 'Aesthetic' },
	{ name: 'Hi-NRG', group: 'Aesthetic' },
	{ name: 'High School Dream', group: 'Aesthetic' },
	{ name: 'Hikecore', group: 'Aesthetic' },
	{ name: 'Hip-Hop', group: 'Aesthetic' },
	{ name: 'Hipness Purgatory', group: 'Aesthetic' },
	{ name: 'Hippie', group: 'Aesthetic' },
	{ name: 'Hipster', group: 'Aesthetic' },
	{ name: 'Hispanicore', group: 'Aesthetic' },
	{ name: 'Historical Americana', group: 'Aesthetic' },
	{ name: 'Holosexual', group: 'Aesthetic' },
	{ name: 'Honeycore', group: 'Aesthetic' },
	{ name: 'Horror', group: 'Aesthetic' },
	{ name: 'Horror Academia', group: 'Aesthetic' },
	{ name: 'Hot Topic', group: 'Aesthetic' },
	{ name: 'Hustlewave', group: 'Aesthetic' },
	{ name: 'Hydrogen', group: 'Aesthetic' },
	{ name: 'Hyperpop', group: 'Aesthetic' },
	{ name: 'Icepunk', group: 'Aesthetic' },
	{ name: 'Imaginarium', group: 'Aesthetic' },
	{ name: 'Indie', group: 'Aesthetic' },
	{ name: 'Indie Sleaze', group: 'Aesthetic' },
	{ name: 'Indicolite', group: 'Aesthetic' },
	{ name: 'Internet Academia', group: 'Aesthetic' },
	{ name: 'Italo Disco', group: 'Aesthetic' },
	{ name: 'Italian Mafia', group: 'Aesthetic' },
	{ name: 'Italian Renaissance', group: 'Aesthetic' },
	{ name: 'Jamcore', group: 'Aesthetic' },
	{ name: 'Jersey Shore', group: 'Aesthetic' },
	{ name: 'Joyride', group: 'Aesthetic' },
	{ name: 'Juggalo', group: 'Aesthetic' },
	{ name: 'Jungle Grunge', group: 'Aesthetic' },
	{ name: 'Junglecore', group: 'Aesthetic' },
	{ name: 'Karasu Zoku', group: 'Aesthetic' },
	{ name: 'Kawaii', group: 'Aesthetic' },
	{ name: 'Kawaii Gamer', group: 'Aesthetic' },
	{ name: 'Key West Kitten', group: 'Aesthetic' },
	{ name: 'Kimoicore', group: 'Aesthetic' },
	{ name: 'Kingcore', group: 'Aesthetic' },
	{ name: 'Knightcore', group: 'Aesthetic' },
	{ name: 'Kogal', group: 'Aesthetic' },
	{ name: 'Kuromicore', group: 'Aesthetic' },
	{ name: 'La Sape', group: 'Aesthetic' },
	{ name: 'Labcore', group: 'Aesthetic' },
	{ name: 'Laborwave', group: 'Aesthetic' },
	{ name: 'Lagenlook', group: 'Aesthetic' },
	{ name: 'Larme Kei', group: 'Aesthetic' },
	{ name: 'Late 2000s Elementary School', group: 'Aesthetic' },
	{ name: 'Libertywave', group: 'Aesthetic' },
	{ name: 'Light Academia', group: 'Aesthetic' },
	{ name: 'Lightcore', group: 'Aesthetic' },
	{ name: 'Lightningwave', group: 'Aesthetic' },
	{ name: 'Lil Girl', group: 'Aesthetic' },
	{ name: 'Liminal Space', group: 'Aesthetic' },
	{ name: 'Lo-Fi', group: 'Aesthetic' },
	{ name: 'Lobotomy-Chic', group: 'Aesthetic' },
	{ name: 'Long Island', group: 'Aesthetic' },
	{ name: 'Lounge', group: 'Aesthetic' },
	{ name: 'Lovecore', group: 'Aesthetic' },
	{ name: 'Lunarpunk', group: 'Aesthetic' },
	{ name: 'Macaute', group: 'Aesthetic' },
	{ name: 'Mad Scientist', group: 'Aesthetic' },
	{ name: 'Magewave', group: 'Aesthetic' },
	{ name: 'Magical Girls', group: 'Aesthetic' },
	{ name: 'Maidcore', group: 'Aesthetic' },
	{ name: 'Mallgoth', group: 'Aesthetic' },
	{ name: 'Mall Ninja', group: 'Aesthetic' },
	{ name: 'McBling', group: 'Aesthetic' },
	{ name: 'Meatcore', group: 'Aesthetic' },
	{ name: 'Medicalcore', group: 'Aesthetic' },
	{ name: 'Medieval', group: 'Aesthetic' },
	{ name: 'MTV Green Sky', group: 'Aesthetic' },
	{ name: 'Memphis', group: 'Aesthetic' },
	{ name: 'Mermaid', group: 'Aesthetic' },
	{ name: 'Metal', group: 'Aesthetic' },
	{ name: 'Metalcore', group: 'Aesthetic' },
	{ name: 'Metalheart', group: 'Aesthetic' },
	{ name: 'Metrosexual', group: 'Aesthetic' },
	{ name: 'Miami Metro', group: 'Aesthetic' },
	{ name: 'Midwest Emo', group: 'Aesthetic' },
	{ name: 'Midwest Gothic', group: 'Aesthetic' },
	{ name: 'Military', group: 'Aesthetic' },
	{ name: 'Milk', group: 'Aesthetic' },
	{ name: 'Miniaturecore', group: 'Aesthetic' },
	{ name: 'Minivan Rock', group: 'Aesthetic' },
	{ name: 'Miscellaneous Academia', group: 'Aesthetic' },
	{ name: 'Mizuiro', group: 'Aesthetic' },
	{ name: 'Mod', group: 'Aesthetic' },
	{ name: 'Modern Brazil', group: 'Aesthetic' },
	{ name: 'Mori Kei', group: 'Aesthetic' },
	{ name: 'Morute', group: 'Aesthetic' },
	{ name: 'Mote Kei', group: 'Aesthetic' },
	{ name: 'Mulchcore', group: 'Aesthetic' },
	{ name: 'Mushroomcore', group: 'Aesthetic' },
	{ name: 'Mosscore', group: 'Aesthetic' },
	{ name: 'MS Paint', group: 'Aesthetic' },
	{ name: 'Mythpunk', group: 'Aesthetic' },
	{ name: 'Nanchatte Seifuku', group: 'Aesthetic' },
	{ name: 'Nanopunk', group: 'Aesthetic' },
	{ name: 'NATOwave', group: 'Aesthetic' },
	{ name: 'Naturecore', group: 'Aesthetic' },
	{ name: 'Nautical', group: 'Aesthetic' },
	{ name: 'Neko', group: 'Aesthetic' },
	{ name: 'Neo-Romanism', group: 'Aesthetic' },
	{ name: 'Neo-Tokyo', group: 'Aesthetic' },
	{ name: 'Nerd', group: 'Aesthetic' },
	{ name: 'Nerdcore', group: 'Aesthetic' },
	{ name: 'New England Gothic', group: 'Aesthetic' },
	{ name: 'New Age', group: 'Aesthetic' },
	{ name: 'New Money', group: 'Aesthetic' },
	{ name: 'New Romantic', group: 'Aesthetic' },
	{ name: 'New Wave', group: 'Aesthetic' },
	{ name: 'Night Luxe', group: 'Aesthetic' },
	{ name: 'Nihilcore', group: 'Aesthetic' },
	{ name: 'Nintencore', group: 'Aesthetic' },
	{ name: 'Normcore', group: 'Aesthetic' },
	{ name: 'Northerness', group: 'Aesthetic' },
	{ name: 'Nostalgiacore', group: 'Aesthetic' },
	{ name: 'Nuclear', group: 'Aesthetic' },
	{ name: 'Nu-Goth', group: 'Aesthetic' },
	{ name: 'Ocean Academia', group: 'Aesthetic' },
	{ name: 'Ocean Grunge', group: 'Aesthetic' },
	{ name: 'Old Hollywood', group: 'Aesthetic' },
	{ name: 'Old Memecore', group: 'Aesthetic' },
	{ name: 'Old Money', group: 'Aesthetic' },
	{ name: 'Old Web', group: 'Aesthetic' },
	{ name: 'Onii Kei', group: 'Aesthetic' },
	{ name: 'Oshare Kei', group: 'Aesthetic' },
	{ name: 'Otaku', group: 'Aesthetic' },
	{ name: 'Otherkin', group: 'Aesthetic' },
	{ name: 'Pachuco', group: 'Aesthetic' },
	{ name: 'Pale Grunge', group: 'Aesthetic' },
	{ name: 'Paleocore', group: 'Aesthetic' },
	{ name: 'Palewave', group: 'Aesthetic' },
	{ name: 'Paramilitary', group: 'Aesthetic' },
	{ name: 'Party Animal', group: 'Aesthetic' },
	{ name: 'Party Kei', group: 'Aesthetic' },
	{ name: 'Pastel Academia', group: 'Aesthetic' },
	{ name: 'Pastel Goth', group: 'Aesthetic' },
	{ name: 'Pastel Punk', group: 'Aesthetic' },
	{ name: 'Peach', group: 'Aesthetic' },
	{ name: 'Pearly', group: 'Aesthetic' },
	{ name: 'Peoplehood', group: 'Aesthetic' },
	{ name: 'Photorealism', group: 'Aesthetic' },
	{ name: 'Pink Parisian', group: 'Aesthetic' },
	{ name: 'Pink Princess', group: 'Aesthetic' },
	{ name: 'Pink Pilates Princess', group: 'Aesthetic' },
	{ name: 'Pin-up', group: 'Aesthetic' },
	{ name: 'Pirate', group: 'Aesthetic' },
	{ name: 'Pixel Cutie', group: 'Aesthetic' },
	{ name: 'Pixiecore', group: 'Aesthetic' },
	{ name: 'Plaguecore', group: 'Aesthetic' },
	{ name: 'Plant Mom', group: 'Aesthetic' },
	{ name: 'Playboy', group: 'Aesthetic' },
	{ name: 'Pop', group: 'Aesthetic' },
	{ name: 'Pop Art', group: 'Aesthetic' },
	{ name: 'Pop Kei', group: 'Aesthetic' },
	{ name: 'Post-Apocalyptic', group: 'Aesthetic' },
	{ name: 'Post-Punk', group: 'Aesthetic' },
	{ name: 'Post-rock', group: 'Aesthetic' },
	{ name: 'Powwow Step', group: 'Aesthetic' },
	{ name: 'Prairiecore', group: 'Aesthetic' },
	{ name: 'Pre-Raphaelite', group: 'Aesthetic' },
	{ name: 'Prehistoricore', group: 'Aesthetic' },
	{ name: 'Preppy', group: 'Aesthetic' },
	{ name: 'Pretty Preppy', group: 'Aesthetic' },
	{ name: 'Pride flags', group: 'Aesthetic' },
	{ name: 'Princecore', group: 'Aesthetic' },
	{ name: 'Princesscore', group: 'Aesthetic' },
	{ name: 'Printcore', group: 'Aesthetic' },
	{ name: 'Progressive Academia', group: 'Aesthetic' },
	{ name: 'Psychedelica', group: 'Aesthetic' },
	{ name: 'Punk', group: 'Aesthetic' },
	{ name: 'Quality Tumblr', group: 'Aesthetic' },
	{ name: 'Queencore', group: 'Aesthetic' },
	{ name: 'Queer Academia', group: 'Aesthetic' },
	{ name: 'Queercore', group: 'Aesthetic' },
	{ name: 'R&B', group: 'Aesthetic' },
	{ name: 'Racaille', group: 'Aesthetic' },
	{ name: 'Ragecore', group: 'Aesthetic' },
	{ name: 'Rainbowcore', group: 'Aesthetic' },
	{ name: 'Rainy Day', group: 'Aesthetic' },
	{ name: 'Randumb', group: 'Aesthetic' },
	{ name: 'Rangercore', group: 'Aesthetic' },
	{ name: 'Ratcore', group: 'Aesthetic' },
	{ name: 'Ravencore', group: 'Aesthetic' },
	{ name: 'Raver', group: 'Aesthetic' },
	{ name: 'Real Life Super Hero', group: 'Aesthetic' },
	{ name: 'Reefwave', group: 'Aesthetic' },
	{ name: 'Regency', group: 'Aesthetic' },
	{ name: 'Regional Gothic', group: 'Aesthetic' },
	{ name: 'Rivethead', group: 'Aesthetic' },
	{ name: 'Roaring 20s', group: 'Aesthetic' },
	{ name: 'Rock', group: 'Aesthetic' },
	{ name: 'Rockabilly', group: 'Aesthetic' },
	{ name: 'Rocketpunk', group: 'Aesthetic' },
	{ name: 'Rococo', group: 'Aesthetic' },
	{ name: 'Roguecore', group: 'Aesthetic' },
	{ name: 'Rollerwave', group: 'Aesthetic' },
	{ name: 'Roma', group: 'Aesthetic' },
	{ name: 'Romantic Academia', group: 'Aesthetic' },
	{ name: 'Romantic Goth', group: 'Aesthetic' },
	{ name: 'Romantic Italian', group: 'Aesthetic' },
	{ name: 'Rotcore', group: 'Aesthetic' },
	{ name: 'Royalcore', group: 'Aesthetic' },
	{ name: 'Rusticcore', group: 'Aesthetic' },
	{ name: 'Salon Kei', group: 'Aesthetic' },
	{ name: 'Salvagepunk', group: 'Aesthetic' },
	{ name: 'Sandalpunk', group: 'Aesthetic' },
	{ name: 'Sanriocore', group: 'Aesthetic' },
	{ name: 'Scene', group: 'Aesthetic' },
	{ name: 'Schizowave', group: 'Aesthetic' },
	{ name: 'Science Academia', group: 'Aesthetic' },
	{ name: 'Scoutcore', group: 'Aesthetic' },
	{ name: 'Scrapbook', group: 'Aesthetic' },
	{ name: 'Scrapper', group: 'Aesthetic' },
	{ name: 'Seapunk', group: 'Aesthetic' },
	{ name: 'Selkiecore', group: 'Aesthetic' },
	{ name: 'Shabby Chic', group: 'Aesthetic' },
	{ name: 'Shanzhai', group: 'Aesthetic' },
	{ name: 'Shamate', group: 'Aesthetic' },
	{ name: 'Sharpies', group: 'Aesthetic' },
	{ name: 'Shibuya Punk', group: 'Aesthetic' },
	{ name: 'Shironuri', group: 'Aesthetic' },
	{ name: 'Shoegaze', group: 'Aesthetic' },
	{ name: 'Sigilkore', group: 'Aesthetic' },
	{ name: 'Sizz', group: 'Aesthetic' },
	{ name: 'Skater', group: 'Aesthetic' },
	{ name: 'Sleepycore', group: 'Aesthetic' },
	{ name: 'Slimepunk', group: 'Aesthetic' },
	{ name: 'Sloanie', group: 'Aesthetic' },
	{ name: 'Snowdrop', group: 'Aesthetic' },
	{ name: 'Snow Bunny', group: 'Aesthetic' },
	{ name: 'Soft Apocalypse', group: 'Aesthetic' },
	{ name: 'Soft Grunge', group: 'Aesthetic' },
	{ name: 'Soft indie', group: 'Aesthetic' },
	{ name: 'Soft Macabre', group: 'Aesthetic' },
	{ name: 'Softie', group: 'Aesthetic' },
	{ name: 'Soggy', group: 'Aesthetic' },
	{ name: 'Solarpunk', group: 'Aesthetic' },
	{ name: 'Southern Belle', group: 'Aesthetic' },
	{ name: 'Southern Gothic', group: 'Aesthetic' },
	{ name: 'Sovietwave', group: 'Aesthetic' },
	{ name: 'Spacecore', group: 'Aesthetic' },
	{ name: 'Space Cowboy', group: 'Aesthetic' },
	{ name: 'Sparklecore', group: 'Aesthetic' },
	{ name: 'Spiritcore', group: 'Aesthetic' },
	{ name: 'Steampunk', group: 'Aesthetic' },
	{ name: 'Synthwave', group: 'Aesthetic' },
	{ name: 'Tacticool', group: 'Aesthetic' },
	{ name: 'Takenokozoku', group: 'Aesthetic' },
	{ name: 'Tanbi Kei', group: 'Aesthetic' },
	{ name: 'Technical Scene', group: 'Aesthetic' },
	{ name: 'Technocore', group: 'Aesthetic' },
	{ name: 'Technozen', group: 'Aesthetic' },
	{ name: 'Techwear', group: 'Aesthetic' },
	{ name: 'Teddies', group: 'Aesthetic' },
	{ name: 'Teenage Dream', group: 'Aesthetic' },
	{ name: 'Teenqueen', group: 'Aesthetic' },
	{ name: 'Teethcore', group: 'Aesthetic' },
	{ name: 'Terrorwave', group: 'Aesthetic' },
	{ name: 'Teslapunk', group: 'Aesthetic' },
	{ name: 'That Girl', group: 'Aesthetic' },
	{ name: 'Theatre Academia', group: 'Aesthetic' },
	{ name: 'Thrasher', group: 'Aesthetic' },
	{ name: 'Thriftcore', group: 'Aesthetic' },
	{ name: 'Tiki', group: 'Aesthetic' },
	{ name: 'Tinkercore', group: 'Aesthetic' },
	{ name: 'Tinycore', group: 'Aesthetic' },
	{ name: 'Tomboy', group: 'Aesthetic' },
	{ name: 'Toxic Core', group: 'Aesthetic' },
	{ name: 'Trad Goth', group: 'Aesthetic' },
	{ name: 'Traditional Korean', group: 'Aesthetic' },
	{ name: 'Trailer Park Princess', group: 'Aesthetic' },
	{ name: 'Trashcore', group: 'Aesthetic' },
	{ name: 'Traumacore', group: 'Aesthetic' },
	{ name: 'Trenchcore', group: 'Aesthetic' },
	{ name: 'Trendercore', group: 'Aesthetic' },
	{ name: 'Trillwave', group: 'Aesthetic' },
	{ name: 'Tropical', group: 'Aesthetic' },
	{ name: 'Tumblewave', group: 'Aesthetic' },
	{ name: 'Tupinipunk', group: 'Aesthetic' },
	{ name: 'Tweencore', group: 'Aesthetic' },
	{ name: 'Twee', group: 'Aesthetic' },
	{ name: 'Ukiyo-e', group: 'Aesthetic' },
	{ name: 'Unicorncore', group: 'Aesthetic' },
	{ name: 'Urban Fantasy', group: 'Aesthetic' },
	{ name: 'Urbancore', group: 'Aesthetic' },
	{ name: 'Utopian Scholastic', group: 'Aesthetic' },
	{ name: 'VARIOUSXYZ', group: 'Aesthetic' },
	{ name: 'Vacation Dadcore', group: 'Aesthetic' },
	{ name: 'Vampire', group: 'Aesthetic' },
	{ name: 'Vaporwave', group: 'Aesthetic' },
	{ name: 'Vectorbloom', group: 'Aesthetic' },
	{ name: 'Vectorheart', group: 'Aesthetic' },
	{ name: 'Vibrant Academia', group: 'Aesthetic' },
	{ name: 'Victorian', group: 'Aesthetic' },
	{ name: 'Victorian Goth', group: 'Aesthetic' },
	{ name: 'Victorian Urban Poverty', group: 'Aesthetic' },
	{ name: 'Viking', group: 'Aesthetic' },
	{ name: 'Villagecore', group: 'Aesthetic' },
	{ name: 'Villaincore', group: 'Aesthetic' },
	{ name: 'Vintage British Sportsman', group: 'Aesthetic' },
	{ name: 'Vintage Parisian', group: 'Aesthetic' },
	{ name: "Virgo's Tears", group: 'Aesthetic' },
	{ name: 'Visual Kei', group: 'Aesthetic' },
	{ name: 'VSCO', group: 'Aesthetic' },
	{ name: 'VSCO Preppy', group: 'Aesthetic' },
	{ name: 'Voidcore', group: 'Aesthetic' },
	{ name: 'Voidpunk', group: 'Aesthetic' },
	{ name: 'Vulture Culture', group: 'Aesthetic' },
	{ name: 'Wabi-Sabi', group: 'Aesthetic' },
	{ name: 'Waif', group: 'Aesthetic' },
	{ name: 'Waldorf', group: 'Aesthetic' },
	{ name: 'Wanderlust', group: 'Aesthetic' },
	{ name: 'Warmcore', group: 'Aesthetic' },
	{ name: 'Weathercore', group: 'Aesthetic' },
	{ name: 'Web Creep', group: 'Aesthetic' },
	{ name: 'Weeaboo', group: 'Aesthetic' },
	{ name: 'Weirdcore', group: 'Aesthetic' },
	{ name: 'Werewolf', group: 'Aesthetic' },
	{ name: 'Western', group: 'Aesthetic' },
	{ name: 'Wetcore', group: 'Aesthetic' },
	{ name: 'Winter', group: 'Aesthetic' },
	{ name: 'Winter Fairy Coquette', group: 'Aesthetic' },
	{ name: 'Witchy Academia', group: 'Aesthetic' },
	{ name: 'Witch House', group: 'Aesthetic' },
	{ name: 'Witchcore', group: 'Aesthetic' },
	{ name: 'Wizardcore', group: 'Aesthetic' },
	{ name: 'Wonderland', group: 'Aesthetic' },
	{ name: 'Woodland goth', group: 'Aesthetic' },
	{ name: 'Wormcore', group: 'Aesthetic' },
	{ name: 'Writer Academia', group: 'Aesthetic' },
	{ name: 'Wuxia', group: 'Aesthetic' },
	{ name: 'XO', group: 'Aesthetic' },
	{ name: 'Y2K', group: 'Aesthetic' },
	{ name: 'Yakuza', group: 'Aesthetic' },
	{ name: 'Yami Kawaii', group: 'Aesthetic' },
	{ name: 'Yankeecore', group: 'Aesthetic' },
	{ name: 'Yanki', group: 'Aesthetic' },
	{ name: 'Youthquake', group: 'Aesthetic' },
	{ name: 'Yume Kawaii', group: 'Aesthetic' },
	{ name: 'Zombiecore', group: 'Aesthetic' },
];

export default Aesthetics;
