import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import Popper from '@mui/material/Popper';
import { useTheme, styled } from '@mui/material/styles';
import { VariableSizeList } from 'react-window';
import Typography from '@mui/material/Typography';

import styles from './dropdown-options/styles.js';
import colors from './dropdown-options/colors.js';
import mediums from './dropdown-options/mediums.js';

import moods from './dropdown-options/moods.js';
import forms from './dropdown-options/forms.js';
import presentations from './dropdown-options/presentations.js';

const PopperMy = function (props) {
	return (
		<Popper
			{...props}
			style={{ width: 320, height: 0 }}
			placement='bottom-start'
		/>
	);
};

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
	const { data, index, style } = props;
	const dataSet = data[index];
	const inlineStyle = {
		...style,
		top: style.top + LISTBOX_PADDING,
	};

	if (dataSet.hasOwnProperty('group')) {
		return (
			<ListSubheader
				key={dataSet.key}
				component='div'
				style={inlineStyle}
			>
				{dataSet.group}
			</ListSubheader>
		);
	}

	return (
		<Typography component='li' {...dataSet[0]} noWrap style={inlineStyle}>
			{`${dataSet[1]}`}
		</Typography>
	);
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
	const outerProps = React.useContext(OuterElementContext);
	return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
	const ref = React.useRef(null);
	React.useEffect(() => {
		if (ref.current != null) {
			ref.current.resetAfterIndex(0, true);
		}
	}, [data]);
	return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
	props,
	ref
) {
	const { children, ...other } = props;
	const itemData = [];
	children.forEach((item) => {
		itemData.push(item);
		itemData.push(...(item.children || []));
	});

	const theme = useTheme();
	const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
		noSsr: true,
	});
	const itemCount = itemData.length;
	const itemSize = smUp ? 36 : 48;

	const getChildSize = (child) => {
		if (child.hasOwnProperty('group')) {
			return 48;
		}

		return itemSize;
	};

	const getHeight = () => {
		if (itemCount > 8) {
			return 8 * itemSize;
		}
		return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
	};

	const gridRef = useResetCache(itemCount);

	return (
		<div ref={ref}>
			<OuterElementContext.Provider value={other}>
				<VariableSizeList
					itemData={itemData}
					height={getHeight() + 2 * LISTBOX_PADDING}
					width='100%'
					ref={gridRef}
					outerElementType={OuterElementType}
					innerElementType='ul'
					itemSize={(index) => getChildSize(itemData[index])}
					overscanCount={5}
					itemCount={itemCount}
				>
					{renderRow}
				</VariableSizeList>
			</OuterElementContext.Provider>
		</div>
	);
});

// ListboxComponent.propTypes = {
// 	children: PropTypes.node,
// };

export default function Virtualize({
	useRandom,
	useReset,
	category,
	handleSelectAction,
}) {
	const [personName, setPersonName] = React.useState([]);
	let names = [];

	switch (category) {
		case 'Style':
			names = styles;
			break;
		case 'Color':
			names = colors;
			break;
		case 'Medium':
			names = mediums;
			break;
		case 'Mood':
			names = moods;
			break;
		case 'Form':
			names = forms;
			break;
		case 'Presentation':
			names = presentations;
			break;
		default:
			names = [];
	}

	names = names.sort(function (a, b) {
		if (a.group === b.group) {
			return a.name.localeCompare(b.name);
		}
		return a.group.localeCompare(b.group);
	});

	const handleChange = (event, value) => {
		setPersonName(value);
		handleSelectAction(value);
	};

	useEffect(() => {
		if (useRandom) {
			let random = names[Math.floor(Math.random() * names.length)];
			if (random) {
				setPersonName([random]);
				handleSelectAction([random]);
			}
		}
		if (useReset) {
			setPersonName([]);
			handleSelectAction([]);
		}
	}, [useRandom, useReset]);

	return (
		<Autocomplete
			multiple
			limitTags={2}
			value={personName}
			disableCloseOnSelect
			getOptionLabel={(item) => item.name}
			id='virtualize-demo'
			sx={{ width: 320 }}
			disableListWrap
			onChange={handleChange}
			PopperComponent={PopperMy}
			ListboxComponent={ListboxComponent}
			options={names}
			groupBy={(option) => (option.group ? option.group : category + 's')}
			renderInput={(params) => <TextField {...params} label={category} />}
			renderOption={(props, option, state) => [
				props,
				option.name,
				state.index,
			]}
			// TODO: Post React 18 update - validate this conversion, look like a hidden bug
			renderGroup={(params) => params}
		/>
	);
}
