import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { Instagram } from '@mui/icons-material';

export default function ButtonAppBar() {
	return (
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position='static' style={{ backgroundColor: '#37474f' }}>
				<Toolbar>
					{/* <IconButton
						size='large'
						edge='start'
						color='inherit'
						aria-label='menu'
						sx={{ mr: 2 }}
					>
						<MenuIcon />
					</IconButton> */}
					<Typography
						variant='h6'
						component='div'
						style={{ fontWeight: 'bold' }}
						sx={{ flexGrow: 1 }}
					>
						✨ prompt spells ✨
					</Typography>
					<Button
						href='https://www.instagram.com/promptspells/'
						target={'_blank'}
						style={{ fontWeight: 'bold' }}
						color='inherit'
					>
						<Instagram />
					</Button>
					{/* <Button color='inherit'>About</Button> */}
					{/* <Button
						style={{ fontWeight: 'bold', letterSpacing: 2 }}
						color='inherit'
					>
						Premium
					</Button> */}
					{/* <Button color='inherit'>About</Button> */}
				</Toolbar>
			</AppBar>
		</Box>
	);
}
