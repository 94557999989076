const mediums = [
	{ name: '3D render', group: 'General' },
	{ name: 'painting', group: 'General' },
	{ name: 'drawing', group: 'General' },
	{ name: 'sculpture', group: 'General' },
	{ name: 'printmaking', group: 'General' },
	{ name: 'photography', group: 'General' },
	{ name: 'digital art', group: 'General' },
	{ name: 'collage', group: 'General' },
	{ name: 'assemblage', group: 'General' },
	{ name: 'installation art', group: 'General' },
	{ name: 'performance art', group: 'General' },
	{ name: 'video art', group: 'General' },
	{ name: 'textile art', group: 'General' },
	{ name: 'ceramics', group: 'General' },
	{ name: 'glass art', group: 'General' },
	{ name: 'metal art', group: 'General' },
	{ name: 'wood art', group: 'General' },
	{ name: 'mixed media', group: 'General' },
	{ name: 'land art', group: 'General' },
	{ name: 'street art', group: 'General' },
	{ name: 'statue', group: 'General' },
	{ name: 'bubblegram', group: 'General' },
	{ name: 'carving', group: 'General' },
	{ name: 'performance props', group: 'General' },
	{ name: 'acrylic paint', group: 'Materials' },
	{ name: 'oil paint', group: 'Materials' },
	{ name: 'watercolor paint', group: 'Materials' },
	{ name: 'gouache paint', group: 'Materials' },
	{ name: 'tempera paint', group: 'Materials' },
	{ name: 'pastels', group: 'Materials' },
	{ name: 'charcoal', group: 'Materials' },
	{ name: 'graphite', group: 'Materials' },
	{ name: 'ink', group: 'Materials' },
	{ name: 'pencil', group: 'Materials' },
	{ name: 'pen', group: 'Materials' },
	{ name: 'markers', group: 'Materials' },
	{ name: 'colored pencils', group: 'Materials' },
	{ name: 'chalk', group: 'Materials' },
	{ name: 'plants', group: 'Materials' },
	{ name: 'crayon', group: 'Materials' },
	{ name: 'collage materials', group: 'Materials' },
	{ name: 'clay', group: 'Materials' },
	{ name: 'plaster', group: 'Materials' },
	{ name: 'marble', group: 'Materials' },
	{ name: 'stone', group: 'Materials' },
	{ name: 'sand', group: 'Materials' },
	{ name: 'metal', group: 'Materials' },
	{ name: 'bronze', group: 'Materials' },
	{ name: 'copper', group: 'Materials' },
	{ name: 'silver', group: 'Materials' },
	{ name: 'gold', group: 'Materials' },
	{ name: 'iron', group: 'Materials' },
	{ name: 'jade', group: 'Materials' },
	{ name: 'butter', group: 'Materials' },
	{ name: 'ice', group: 'Materials' },
	{ name: 'steel', group: 'Materials' },
	{ name: 'paper', group: 'Materials' },
	{ name: 'cardboard', group: 'Materials' },
	{ name: 'fabric', group: 'Materials' },
	{ name: 'glass', group: 'Materials' },
	{ name: 'digital', group: 'Materials' },
	{ name: 'natural materials', group: 'Materials' },
	{ name: 'wood', group: 'Materials' },
	{ name: 'crystal', group: 'Materials' },
	{ name: 'diamond', group: 'Materials' },
	{ name: 'porcelain', group: 'Materials' },
	{ name: 'yarn', group: 'Materials' },
	{ name: 'spray paint', group: 'Materials' },
	{ name: 'armor', group: 'Accessories' },
	{ name: 'bag', group: 'Accessories' },
	{ name: 'clothing', group: 'Accessories' },
	{ name: 'costume', group: 'Accessories' },
	{ name: 'cosplay', group: 'Accessories' },
	{ name: 'jewelery', group: 'Accessories' },
	{ name: 'necklace', group: 'Accessories' },
	{ name: 'outfit', group: 'Accessories' },
	{ name: 'ring', group: 'Accessories' },
	{ name: 'shoes', group: 'Clothing' },
	{ name: 'shirt', group: 'Clothing' },
	{ name: 'skirt', group: 'Clothing' },
	{ name: 'dress', group: 'Clothing' },
	{ name: 'pants', group: 'Clothing' },
	{ name: 'shorts', group: 'Clothing' },
	{ name: 'socks', group: 'Clothing' },
	{ name: 'coat', group: 'Clothing' },
	{ name: 'body art', group: 'Body' },
	{ name: 'face paint', group: 'Body' },
	{ name: 'haircut', group: 'Body' },
	{ name: 'hairstyle', group: 'Body' },
	{ name: 'makeup', group: 'Body' },
	{ name: 'nail art', group: 'Body' },
	{ name: 'tattoo', group: 'Body' },
	{ name: 'amigurumi', group: 'Specialty' },
	{ name: 'cave painting', group: 'Specialty' },
	{ name: 'diorama', group: 'Specialty' },
	{ name: 'doll', group: 'Specialty' },
	{ name: 'doll house', group: 'Specialty' },
	{ name: 'installation art', group: 'Specialty' },
	{ name: 'knitting pattern', group: 'Specialty' },
	{ name: 'satellite image', group: 'Specialty' },
	{ name: 'etching', group: 'Specialty' },
	{ name: 'embroidery', group: 'Specialty' },
	{ name: 'movie poster', group: 'Specialty' },
	{ name: 'stencil', group: 'Specialty' },
	{ name: 'propaganda poster', group: 'Specialty' },
	{ name: 'fabric pattern', group: 'Specialty' },
	{ name: 'fiberglass', group: 'Specialty' },
	{ name: 'geometric', group: 'Specialty' },
	{ name: 'interior design', group: 'Specialty' },
	{ name: 'linocut', group: 'Specialty' },
	{ name: 'mural', group: 'Specialty' },
	{ name: 'toy', group: 'Specialty' },
	{ name: 'needle felting', group: 'Specialty' },
	{ name: 'origami', group: 'Specialty' },
	{ name: 'isometric', group: 'Specialty' },
	{ name: 'airbrush', group: 'Specialty' },
	{ name: 'claymation', group: 'Specialty' },
	{ name: 'mosaic', group: 'Specialty' },
	{ name: 'paperweight', group: 'Specialty' },
	{ name: 'papercraft', group: 'Specialty' },
	{ name: 'snow globe', group: 'Specialty' },
	{ name: 'paper mache', group: 'Specialty' },
	{ name: 'paper embossing', group: 'Specialty' },
	{ name: 'perfer beads', group: 'Specialty' },
	{ name: 'plastic', group: 'Specialty' },
	{ name: 'bust', group: 'Specialty' },
	{ name: 'pop up book', group: 'Specialty' },
	{ name: 'puppet', group: 'Specialty' },
];

export default mediums;
