import { Button } from '@mui/material';
import React from 'react';

const RandomButton = ({ handleGenerateRandom }) => {
	return (
		<>
			<Button
				onClick={handleGenerateRandom}
				style={{ marginBottom: '15px' }}
				variant='contained'
				color='success'
			>
				Random
			</Button>
		</>
	);
};

export default RandomButton;
