const form = [
	{ name: 'flat', group: '' },
	{ name: 'abstracted', group: '' },
	{ name: 'simplified', group: '' },
	{ name: 'stylized', group: '' },
	{ name: '3D', group: '' },
	{ name: 'isometric', group: '' },
	{ name: 'low poly', group: '' },
	{ name: 'realistic', group: '' },
	{ name: 'sharp', group: '' },
	{ name: 'etching', group: '' },
	{ name: 'detailed', group: '' },
	{ name: 'blurred', group: '' },
	{ name: 'obscured', group: '' },
	{ name: 'overlapping', group: '' },
	{ name: 'indistinct', group: '' },
	{ name: 'distorted', group: '' },
	{ name: 'exaggerated', group: '' },
	{ name: 'geometric', group: '' },
	{ name: 'cartoon', group: '' },
	{ name: 'linear', group: '' },
	{ name: 'long', group: '' },
	{ name: 'narrow', group: '' },
	{ name: 'hard-edged', group: '' },
	{ name: 'soft-edged', group: '' },
];

export default form;
