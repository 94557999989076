import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { ContentCopy, Check } from '@mui/icons-material';
import { Alert, Slide } from '@mui/material';

export default function PromptHistory({ prompts }) {
	const [showCopySuccessAlert, setShowCopySuccessAlert] = useState(false);

	return (
		<>
			<Slide
				direction='up'
				in={showCopySuccessAlert}
				mountOnEnter
				unmountOnExit
			>
				<Alert
					icon={<Check />}
					style={{
						fontSize: 18,
						position: 'fixed',
						bottom: 50,
						right: -10,
						zIndex: 999,
						marginTop: -10,
						border: '1px solid lightgrey',
						width: '260px',
						boxShadow: '0 1px 1px 0',
					}}
				>
					Copied to clipboard!
				</Alert>
			</Slide>
			<TableContainer
				component={Paper}
				style={{
					marginTop: '25px',
					marginBottom: '100px',
					maxHeight: '300px',
				}}
			>
				<Table sx={{ minWidth: 650 }} aria-label='simple table'>
					<TableBody>
						{prompts.map((row) => (
							<TableRow
								key={row}
								sx={{
									'&:last-child td, &:last-child th': {
										border: 0,
									},
								}}
							>
								<TableCell>{row}</TableCell>
								<TableCell
									style={{
										textAlign: 'right',
										position: 'sticky',
										right: -1,
										background: 'white',
										zIndex: 998,
									}}
								>
									<ContentCopy
										style={{
											color: '#757575',
											cursor: 'pointer',
											textAlign: 'right',
										}}
										onClick={() => {
											setShowCopySuccessAlert(true);
											navigator.clipboard.writeText(
												row.toString()
											);
											setTimeout(() => {
												setShowCopySuccessAlert(false);
											}, 3000);
										}}
									/>
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
