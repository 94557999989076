const moods = [
	{ name: 'brash', group: 'Positive mood, high energy' },
	{ name: 'bright', group: 'Positive mood, high energy' },
	{ name: 'colorful', group: 'Positive mood, high energy' },
	{ name: 'dynamic', group: 'Positive mood, high energy' },
	{ name: 'ecstatic', group: 'Positive mood, high energy' },
	{ name: 'energetic', group: 'Positive mood, high energy' },
	{ name: 'exciting', group: 'Positive mood, high energy' },
	{ name: 'expressive', group: 'Positive mood, high energy' },
	{ name: 'hot', group: 'Positive mood, high energy' },
	{ name: 'joyful', group: 'Positive mood, high energy' },
	{ name: 'lively', group: 'Positive mood, high energy' },
	{ name: 'passionate', group: 'Positive mood, high energy' },
	{ name: 'psychedelic', group: 'Positive mood, high energy' },
	{ name: 'rich', group: 'Positive mood, high energy' },
	{ name: 'romantic', group: 'Positive mood, high energy' },
	{ name: 'saturated', group: 'Positive mood, high energy' },
	{ name: 'spirited', group: 'Positive mood, high energy' },
	{ name: 'vibrant', group: 'Positive mood, high energy' },
	{ name: 'vivid', group: 'Positive mood, high energy' },
	{ name: 'dark', group: 'Negative mood, high energy' },
	{ name: 'dreadful', group: 'Negative mood, high energy' },
	{ name: 'apocalyptic', group: 'Negative mood, high energy' },
	{ name: 'forbidding', group: 'Negative mood, high energy' },
	{ name: 'frightful', group: 'Negative mood, high energy' },
	{ name: 'ghastly', group: 'Negative mood, high energy' },
	{ name: 'ghostly', group: 'Negative mood, high energy' },
	{ name: 'gloomy', group: 'Negative mood, high energy' },
	{ name: 'harrowing', group: 'Negative mood, high energy' },
	{ name: 'haunting', group: 'Negative mood, high energy' },
	{ name: 'hideous', group: 'Negative mood, high energy' },
	{ name: 'ominous', group: 'Negative mood, high energy' },
	{ name: 'shadowy', group: 'Negative mood, high energy' },
	{ name: 'shocking', group: 'Negative mood, high energy' },
	{ name: 'sinister', group: 'Negative mood, high energy' },
	{ name: 'stormy', group: 'Negative mood, high energy' },
	{ name: 'threatening', group: 'Negative mood, high energy' },
	{ name: 'terrifying', group: 'Negative mood, high energy' },
	{ name: 'unnerving', group: 'Negative mood, high energy' },
	{ name: 'balmy', group: 'Positive mood, low energy' },
	{ name: 'calm', group: 'Positive mood, low energy' },
	{ name: 'comforting', group: 'Positive mood, low energy' },
	{ name: 'cozy', group: 'Positive mood, low energy' },
	{ name: 'delicate', group: 'Positive mood, low energy' },
	{ name: 'elegant', group: 'Positive mood, low energy' },
	{ name: 'ethereal', group: 'Positive mood, low energy' },
	{ name: 'graceful', group: 'Positive mood, low energy' },
	{ name: 'light', group: 'Positive mood, low energy' },
	{ name: 'mild', group: 'Positive mood, low energy' },
	{ name: 'peaceful', group: 'Positive mood, low energy' },
	{ name: 'placid', group: 'Positive mood, low energy' },
	{ name: 'relaxed', group: 'Positive mood, low energy' },
	{ name: 'serene', group: 'Positive mood, low energy' },
	{ name: 'soft', group: 'Positive mood, low energy' },
	{ name: 'soothing', group: 'Positive mood, low energy' },
	{ name: 'subtle', group: 'Positive mood, low energy' },
	{ name: 'tender', group: 'Positive mood, low energy' },
	{ name: 'tranquil', group: 'Positive mood, low energy' },
	{ name: 'quiet', group: 'Positive mood, low energy' },
	{ name: 'bleak', group: 'Negative mood, low energy' },
	{ name: 'depressing', group: 'Negative mood, low energy' },
	{ name: 'dismal', group: 'Negative mood, low energy' },
	{ name: 'dreary', group: 'Negative mood, low energy' },
	{ name: 'funereal', group: 'Negative mood, low energy' },
	{ name: 'gloomy', group: 'Negative mood, low energy' },
	{ name: 'melancholic', group: 'Negative mood, low energy' },
	{ name: 'mournful', group: 'Negative mood, low energy' },
	{ name: 'muted', group: 'Negative mood, low energy' },
	{ name: 'sad', group: 'Negative mood, low energy' },
	{ name: 'somber', group: 'Negative mood, low energy' },
	{ name: 'subdued', group: 'Negative mood, low energy' },
	{ name: 'tired', group: 'Negative mood, low energy' },
	{ name: 'weary', group: 'Negative mood, low energy' },
];

export default moods;
