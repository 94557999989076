const presentations = [
	{ name: 'anaglyph', group: 'Film types' },
	{ name: 'autochrome', group: 'Film types' },
	{ name: 'black and white', group: 'Film types' },
	{ name: 'bleach bypass', group: 'Film types' },
	{ name: 'cameraphone', group: 'Film types' },
	{ name: 'camera obscura', group: 'Film types' },
	{ name: 'CCTV', group: 'Film types' },
	{ name: 'color splash', group: 'Film types' },
	{ name: 'contact sheet', group: 'Film types' },
	{ name: 'cyanotype', group: 'Film types' },
	{ name: 'daguerreotype', group: 'Film types' },
	{ name: 'disposable camera', group: 'Film types' },
	{ name: 'double exposure', group: 'Film types' },
	{ name: 'infrared', group: 'Film types' },
	{ name: 'instagram', group: 'Film types' },
	{ name: 'kodachrome', group: 'Film types' },
	{ name: 'lomography', group: 'Film types' },
	{ name: 'polaroid', group: 'Film types' },
	{ name: 'redscale', group: 'Film types' },
	{ name: 'solarized', group: 'Film types' },
	{ name: 'fish-eye lens', group: 'Lenses' },
	{ name: 'high-quality lens', group: 'Lenses' },
	{ name: 'macro lens', group: 'Lenses' },
	{ name: 'telephoto lens', group: 'Lenses' },
	{ name: 'ultra hd', group: 'Lenses' },
	{ name: 'wide angle lens', group: 'Lenses' },
	{ name: 'ambient lighting', group: 'Lighting' },
	{ name: 'backlighting', group: 'Lighting' },
	{ name: 'blue hour', group: 'Lighting' },
	{ name: 'cold lighting', group: 'Lighting' },
	{ name: 'colorful lighting', group: 'Lighting' },
	{ name: 'flash photography', group: 'Lighting' },
	{ name: 'golden hour', group: 'Lighting' },
	{ name: 'high-key lighting', group: 'Lighting' },
	{ name: 'low-key lighting', group: 'Lighting' },
	{ name: 'midday', group: 'Lighting' },
	{ name: 'overhead sunlight', group: 'Lighting' },
	{ name: 'overcast', group: 'Lighting' },
	{ name: 'silhouetted', group: 'Lighting' },
	{ name: 'soft shadow', group: 'Lighting' },
	{ name: 'studio lighting', group: 'Lighting' },
	{ name: 'warm lighting', group: 'Lighting' },
	{ name: 'aerial view', group: 'Position' },
	{ name: 'overhead view', group: 'Position' },
	{ name: 'low angle', group: 'Position' },
	{ name: 'tilted frame', group: 'Position' },
	{ name: 'over the shoulder shot', group: 'Position' },
	{ name: 'close-up', group: 'Proximity' },
	{ name: 'knolling', group: 'Proximity' },
	{ name: 'extreme close-up', group: 'Proximity' },
	{ name: 'extreme long shot', group: 'Proximity' },
	{ name: 'long shot', group: 'Proximity' },
	{ name: 'medium shot', group: 'Proximity' },
	{ name: '4K/8K', group: 'Quality' },
	{ name: '15mm wide-angle lens', group: 'Quality' },
	{ name: '35mm lens', group: 'Quality' },
	{ name: '85mm lens', group: 'Quality' },
	{ name: '200mm lens', group: 'Quality' },
	{ name: 'award-winning', group: 'Quality' },
	{ name: 'cinematic movie photograph', group: 'Quality' },
	{ name: 'bokeh', group: 'Settings' },
	{ name: 'kaleidoscope', group: 'Settings' },
	{ name: 'lens flare', group: 'Settings' },
	{ name: 'long exposure', group: 'Settings' },
	{ name: 'motion blur', group: 'Settings' },
	{ name: 'tilt shift', group: 'Settings' },
	{ name: 'fast shutter speed', group: 'Settings' },
	{ name: 'slow shutter speed', group: 'Settings' },
];

export default presentations;
